import { APIFilter } from '@/utils/api'

export default {
  async selectLookups (Vue, idsobre) {
    let apiCalls = []
    const apiFilterSobre = new APIFilter()
      .addGT('estado', 0)
      .addExact('idsobre', idsobre)
    apiCalls.push({ name: 'selectSobreDestinatarios', method: 'sobreDestinatario.select', params: { filter: apiFilterSobre } })
    apiCalls.push({ name: 'cuerpoMensajeSobre', method: 'sobre.CuerpoMensajeSobre', params: { idsobre: idsobre } })
    apiCalls.push({ name: 'selectSobre', method: 'sobre.select', params: { filter: apiFilterSobre } })
    const apiFilterSobreFaltan = new APIFilter()
      .addExact('idsobre', idsobre)
    apiCalls.push({ name: 'selectSobreDestinatariosFaltan', method: 'sobreDestinatario.selectDestinatariosFaltan', params: { filter: apiFilterSobreFaltan } })
    return await Vue.$api.batchCall(apiCalls)
  },
}
