<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="asunto"
      :rules="formRules.asunto"
    >
      <v-text-field
        v-model="form.asunto"
        label="Asunto"
        clearable
        :error-messages="errors"
      />
    </ValidationProvider>
    <b10-textarea
      v-model="form.body"
      label="Mensaje"
    />
    <b10-subheader
      label="Destinatarios"
      :icon="$vuetify.icons.values.send"
    />
    <v-card
      v-for="(dest, key) in todosLosDestinatarios"
      :key="key"
      class="mb-3"
      tile
    >
      <div
        v-if="dest.length > 0"
      >
        <v-card-subtitle>
          <strong>{{ $options.filters.capitalize(key) }}:</strong>
        </v-card-subtitle>
        <v-card-text
          v-for="(email, keyEmail) in dest"
          :key="keyEmail"
        >
          {{ email.email }}
          <v-chip
            v-if="!email.idsobre_destinatario"
            small
          >
            Previsto
          </v-chip>
        </v-card-text>
      </div>
    </v-card>
    <v-btn
      block
      color="secondary"
      dark
      @click.stop="clickEditDestinatarios()"
    >
      <v-icon
        left
      >
        {{ $vuetify.icons.values.destinatario }}
      </v-icon> Editar destinatarios
    </v-btn>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './SobreEnviarFormData'
import { SOBRE_DESTINATARIO } from '@/utils/consts'
import _ from '@/utils/lodash'

export default {
  mixins: [formMixin],
  props: {
    idsobre: {
      type: [Number, String],
      default: null,
    },
  },
  data () {
    return {
      form: {
        destinatarios: [],
        asunto: '',
        body: null,
      },
      formRules: {
        asunto: { required: true },
      },
      SOBRE_DESTINATARIO: SOBRE_DESTINATARIO,
      todosLosDestinatarios: {
        normal: [],
        cc: [],
        cco: [],
      }
    }
  },
  async created () {
    const resp = await Data.selectLookups(this, this.idsobre)
    const sobreDestinatarios = _.unionBy(
      resp.data.selectSobreDestinatarios.result.dataset,
      resp.data.selectSobreDestinatariosFaltan.result.dataset,
      'email'
    )
    for (const destinatario of sobreDestinatarios) {
      if (destinatario.hasOwnProperty('clasificacion')) {
        if (destinatario.clasificacion == SOBRE_DESTINATARIO.clasificacion.normal) {
          this.todosLosDestinatarios['normal'].push({
            email: destinatario.email,
            clasificacion: destinatario.clasificacion,
            idsobre_destinatario: destinatario.idsobre_destinatario,
          })
        } else if (destinatario.clasificacion == SOBRE_DESTINATARIO.clasificacion.cc) {
          this.todosLosDestinatarios['cc'].push({
            email: destinatario.email,
            clasificacion: destinatario.clasificacion,
            idsobre_destinatario: destinatario.idsobre_destinatario,
          })
        } else if (destinatario.clasificacion == SOBRE_DESTINATARIO.clasificacion.cco) {
          this.todosLosDestinatarios['cco'].push({
            email: destinatario.email,
            clasificacion: destinatario.clasificacion,
            idsobre_destinatario: destinatario.idsobre_destinatario,
          })
        }
      } else {
        this.todosLosDestinatarios['normal'].push({
          email: destinatario.email,
          clasificacion: SOBRE_DESTINATARIO.clasificacion.normal,
          idsobre_destinatario: null,
        })
      }
    }
    this.$set(this.form, 'body', resp.data.cuerpoMensajeSobre.result)
    this.$set(this.form, 'asunto', resp.data.selectSobre.result.dataset[0].mensaje_asunto)
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    clickEditDestinatarios() {
      this.$appRouter.push({
        name: 'sobres__destinatarios__sobre-destinatario-list',
      })
    }
  },
}
</script>
